import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "sv-createassistant",
  "title": "Skapa assistent",
  "seo": {
    "title": "-",
    "descr": "-"
  },
  "back": "Tillbaka",
  "next": "Nästa",
  "emailSection": {
    "mascot": "/img/robbie.png",
    "step": 1,
    "title": "Fyll i din epostadress",
    "text": "Börja med att fylla i din epostadress för att vi ska kunna skicka mer information till dig om din digitala assistent.",
    "email": "Epost"
  },
  "sellingPoints": {
    "title": "Varför en digital assistent?",
    "points": [{
      "text": "Spara tid",
      "icon": "/img/time.png"
    }, {
      "text": "Minimera felmarginal",
      "icon": "/img/search.png"
    }, {
      "icon": "/img/earn.png",
      "text": "Minska kostnader"
    }, {
      "icon": "/img/creative.png",
      "text": "Fokusera på viktigare uppgifter"
    }],
    "step": 2
  },
  "buildAssistant": {
    "title": "Bygg din digitala assistent!",
    "text": "Konfigurera en digital assistent för att ta hand om det ni vill automatisera.",
    "mascot": "/img/hello1.png",
    "step": 2
  },
  "areas": {
    "title": "Vilka avdelningar arbetar ni med?",
    "text": "Vilka av följande avdelningar utför ni idag manuellt repetitiva uppgifter på?",
    "categories": [{
      "icon": "/img/head.png",
      "text": "Fastighet",
      "iconSelection": "faHome"
    }, {
      "text": "Ekonomi",
      "icon": "/img/cloud.png",
      "iconSelection": "faBalanceScale"
    }, {
      "text": "HR",
      "icon": "/img/computer-vision.png",
      "iconSelection": "faUserFriends"
    }, {
      "text": "Administration",
      "icon": "/img/cost.png",
      "iconSelection": "faBox"
    }, {
      "icon": "/img/arrow-right.png",
      "text": "Kundservice",
      "iconSelection": "faComments"
    }, {
      "text": "IT",
      "icon": "/img/arrow.png",
      "iconSelection": "faDatabase"
    }, {
      "text": "Övrigt",
      "icon": "/img/creative.png",
      "iconSelection": "faLightbulb"
    }, {
      "text": "Vet ej",
      "icon": "/img/fb.png",
      "iconSelection": "faQuestion"
    }],
    "step": 3
  },
  "priorities": {
    "step": 4,
    "title": "Vilka är era prioriteringar?",
    "mascot": "/img/happy1.png",
    "categories": [{
      "text": "Spara tid",
      "iconSelection": "faUserFriends"
    }, {
      "text": "Minska kostnader",
      "iconSelection": "faDatabase"
    }, {
      "text": "Minska personalberoende",
      "iconSelection": "faBalanceScale"
    }, {
      "text": "Öka kvalitet",
      "iconSelection": "faHome"
    }, {
      "text": "Annat",
      "iconSelection": "faLightbulb"
    }]
  },
  "timeSpent": {
    "title": "Hur mycket i tid per vecka lägger ni på uppgiften?",
    "step": 5,
    "mascot": "/img/happy1.png",
    "categories": [{
      "text": "1-10 timmar"
    }, {
      "text": "10-40 timmar"
    }, {
      "text": "40+ timmar"
    }, {
      "text": "Vet ej"
    }]
  },
  "demo": {
    "title": "Din digitala assistent är på gång",
    "text": "Vi hör inom kort av oss med ett förslag på hur vi kan hjälpa er. Du kan också boka en tid direkt när det passar dig genom kalendern nedan.",
    "demourl": "https://calendly.com/zimply/demo-med-zimply"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      